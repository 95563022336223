<template>
  <div v-if="project" class="Project">
    <div if="icons.length" class="Project__icons">
      <Icon :icon="icon" v-for="icon in icons" :key="icon.url" />
    </div>
  </div>
</template>

<script>
import { db } from '@/database'
import Icon from '@/components/Icon'
import { mapActions } from 'vuex'

export default {
  name: 'Public',
  components: { Icon },
  props: ['secret'],
  data () {
    return {
      project: null,
      icons: []
    }
  },
  methods: {
    initialize () {
    },
    ...mapActions([
      'setAppLoading'
    ])
  },
  mounted () {
    this.setAppLoading(true)
    this.$binding(null, db.collection('projects').where('secret', '==', this.secret)).then((docs) => {
      this.project = docs[0]
      this.$binding('icons', db.collection('projects').doc(this.project['.key']).collection('icons'))
      this.setAppLoading(false)
    })
  }
}
</script>

<style lang="scss" scoped>
  .Project {}
  .Project__icons {
    display: flex;
    flex-wrap: wrap;
  }
</style>
