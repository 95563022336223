<template>
  <div class="Dropzone">
    <div class="Dropzone__overlay" v-if="isActive"></div>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.Dropzone {
  width: 100%;
  height: 100%;
  position: relative;
}
.Dropzone__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $brown-dark;
  opacity: 0.1;
  z-index: 99;
  pointer-events: none;
}
</style>

<script>
export default {
  components: {},

  props: {
    accept: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      fullscreen: 'fullscreen' in (this.$attrs || {}),
      isActive: false
    }
  },

  methods: {
    getElement () {
      return this.fullscreen ? window : this.$el
    }
  },

  mounted () {
    const element = this.getElement()

    element.addEventListener('dragenter', (e) => {
      e.dataTransfer.dropEffect = 'copy'
      e.preventDefault()

      this.isActive = true
      this.$forceUpdate()
    })

    element.addEventListener('dragover', (e) => {
      e.dataTransfer.dropEffect = 'copy'
      e.preventDefault()

      if (!this.isActive) {
        this.isActive = true
        this.$forceUpdate()
      }
    })

    element.addEventListener('dragleave', (e) => {
      e.dataTransfer.dropEffect = 'none'
      e.preventDefault()

      this.isActive = false
      this.$forceUpdate()
    })

    element.addEventListener('drop', (e) => {
      e.preventDefault()
      e.stopPropagation()

      let files = Array.from(e.dataTransfer.files)

      if (this.accept && this.accept.length) {
        files = files.filter((file) => {
          return this.accept.includes(file.type)
        })
      }

      this.isActive = false
      this.$forceUpdate()

      if (files.length) {
        this.$emit('onFiles', files)
      }
    })
  }
}
</script>
