import date from './date'
import datetime from './datetime'
import dayDate from './dayDate'
import currency from './currency'
import capitalize from './capitalize'

export default {
  date,
  datetime,
  dayDate,
  currency,
  capitalize
}
