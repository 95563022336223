<template>
  <div class="Nav" v-if="project && project['.key']">
    <router-link class="Nav__link" :to="{ name: 'Project', params: { id: project['.key'] } }">
      Icons
    </router-link>

    <router-link class="Nav__link" :to="{ name: 'ProjectSettings', params: { id: project['.key'] } }">
      Settings
    </router-link>

    <router-link class="Nav__link" :to="{ name: 'ProjectExport', params: { id: project['.key'] } }">
      Export
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ProjectNav',
  props: {
    project: {
      default: null
    }
  }
}
</script>
