import { db } from '@/database'

export function upgradeAccount (id) {
  return new Promise((resolve, reject) => {
    const data = {
      plan: 'premium',
      paidAt: new Date()
    }

    db.collection('users')
      .doc(id)
      .update(data)
      .then(() => { resolve() })
      .catch((error) => { reject(error) })
  })
}
