<template>
  <div class="auth">
    <div class="auth__form card">
      <h2 class="h3 mb-4">Almost done...</h2>
      <p>
        We have sent an email with a confirmation link to your email address.
        Please verify your email to continue.
      </p>
      <hr class="mb-4">
      <div>
        <strong>
          Didn't get the email?
        </strong>
        <div>
          <div v-if="!verificationEmailResent">
            <a href="javascript: void(0)" @click="resendVerification()">Re-send verification</a>
          </div>
          <div v-if="verificationEmailResent" class="text-success">
            Email sent
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/database'

export default {
  name: 'verifyEmail',
  metaInfo () {
    return {
      title: 'Verify your email'
    }
  },
  data: function () {
    return {
      interval: null,
      verificationEmailResent: null,
      currentUser: auth.currentUser
    }
  },
  methods: {
    resendVerification () {
      return auth.currentUser.sendEmailVerification().then(() => {
        this.verificationEmailResent = true
        // Email sent.
      }).catch((error) => {
        // An error happened.
        alert(error.message)
      })
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      // reload user instance
      auth.currentUser.reload()

      // redirect after verification
      if (auth.currentUser.emailVerified) {
        clearInterval(this.interval)
        this.$router.replace('/app')
      }
    }, 2500)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
