<template>
  <div v-if="email" class="Avatar__container">
    <img :src="imageUrl" alt="" class="Avatar" :class="className" />
  </div>
</template>

<script>
import md5 from 'md5'

export default {
  name: 'Avatar',
  props: {
    email: {

    },
    size: {
      default: 'md'
    }
  },
  data () {
    return {
      defaultUrl: 'https://webfontapp.com/static/images/avatar.jpg'
    }
  },
  computed: {
    className () {
      return `Avatar--${this.size}`
    },
    imageUrl () {
      if (!this.email) {
        return this.defaultUrl
      } else {
        const gravatarHash = md5(this.email)
        return `https://secure.gravatar.com/avatar/${gravatarHash}.jpg?s=320&default=${this.defaultUrl}`
      }
    }
  }
}
</script>

<style lang="scss">
.Avatar__container {
  display: inline;
}
.Avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 0;

  &--sm {
    width: 28px;
    height: 28px;
  }

  &--lg {
    width: 64px;
    height: 64px;
  }
}
</style>
