<template>
  <div class="Project__body">
    <div class="Settings__section">
      <h5>Font settings</h5>
      <form>
        <div class="form-group">
          <label class="form-label" for="fontName">Font name</label>
          <LoadingInput :loading="isSavingFontName">
            <input @blur="updateFontName" v-model="fontName" type="text" class="form-control" id="fontName" placeholder="Enter font name">
          </LoadingInput>
        </div>
        <div class="form-group">
          <label class="form-label" for="iconPrefix">Icon prefix</label>
          <LoadingInput :loading="isSavingIconPrefix">
            <input @blur="updateIconPrefix" v-model="iconPrefix" type="text" class="form-control" id="iconPrefix" placeholder="Select icon prefix" aria-describedby="iconPrefixHelp" >
          </LoadingInput>
          <small id="iconPrefixHelp" class="form-text text-muted">This will be used as a prefix for CSS classnames e.g. <code>icon-user</code>.</small>
        </div>
      </form>
    </div>
    <div class="Settings__section" v-if="icons.length">
      <h5>Customize icons</h5>
      <div class="Settings__icons">
        <IconClassname :icon="icon" v-for="icon in icons" :key="icon.url" />
      </div>
    </div>
    <div class="Settings__section" v-if="isProjectOwner">
      <h5>Actions</h5>
      <p>
        <a class="btn btn-sm btn-outline-danger" href="#" @click="deleteProject">Delete project</a>
      </p>
    </div>
  </div>
</template>

<script>
import { auth } from '@/database'

import IconClassname from '@/components/IconClassname'
import LoadingInput from '@/components/LoadingInput'
import { updateProject, deleteProject } from '@/actions/ProjectActions'
import toSlug from '@/utils/slug'

export default {
  name: 'ProjectSettings',
  props: ['project', 'icons', 'id'],
  components: { IconClassname, LoadingInput },
  metaInfo () {
    return {
      title: `${this.project ? this.project.name : ''} | Font settings`
    }
  },
  data () {
    return {
      isSavingFontName: false,
      isSavingIconPrefix: false,
      fontName: this.project.settings ? this.project.settings.fontName : null,
      iconPrefix: this.project.settings ? this.project.settings.iconPrefix : null
    }
  },
  computed: {
    isProjectOwner () {
      return auth.currentUser.uid === this.project.owner
    }
  },
  methods: {
    fontNameChanged () {
      return this.fontName !== this.project.settings.fontName
    },
    iconPrefixChanged () {
      return this.iconPrefix !== this.project.settings.iconPrefix
    },
    updateFontName (e) {
      this.fontName = toSlug(e.target.value) || this.project.settings.fontName

      if (!this.fontNameChanged()) {
        return
      }

      this.isSavingFontName = true
      const data = {
        settings: {
          ...this.project.settings,
          fontName: this.fontName
        }
      }
      updateProject(data, this.id).finally(() => {
        this.isSavingFontName = false
      })
    },
    updateIconPrefix (e) {
      this.iconPrefix = toSlug(e.target.value) || this.project.settings.iconPrefix

      if (!this.iconPrefixChanged()) {
        return
      }

      this.isSavingIconPrefix = true
      const data = {
        settings: {
          ...this.project.settings,
          iconPrefix: this.iconPrefix
        }
      }
      updateProject(data, this.id).finally(() => {
        this.isSavingIconPrefix = false
      })
    },
    deleteProject () {
      if (confirm(`Do you really want to delete project ${this.project.name}?`)) {
        deleteProject(this.project['.key']).then(() => {
          this.$router.replace('/app')
        }).catch((error) => {
          console.log('error', error)
        })
      }
    }
  },
  watch: {
    project (val) {
      if (val.settings) {
        this.fontName = val.settings.fontName
        this.iconPrefix = val.settings.iconPrefix
      }
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  max-width: 520px;
}
h5 {
  color: $gray-900;
  margin-bottom: $margin-sm;
}
.Settings__section {
  & + & {
    margin-top: 2rem;
  }
}
.Settings__icons {
  display: flex;
  flex-wrap: wrap;
}
</style>
