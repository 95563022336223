import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import isElectron from '@/utils/isElectron'

import Homepage from '@/views/Homepage'
import Privacy from '@/views/Privacy'
import CreateCustomIconFont from '@/views/CreateCustomIconFont'
import SvgSpriteAndIconFont from '@/views/SvgSpriteAndIconFont'

import SignIn from '@/views/Account/SignIn'
import SignUp from '@/views/Account/SignUp'
import SignOut from '@/views/Account/SignOut'
import ResetPassword from '@/views/Account/ResetPassword'
import VerifyEmail from '@/views/Account/VerifyEmail'

import ProjectView from '@/views/Project/Index'
import Project from '@/views/Project/Project'
import Create from '@/views/Project/Create'
import ProjectSharing from '@/views/Project/Share'
import ProjectSettings from '@/views/Project/Settings'
import ProjectExport from '@/views/Project/Export'
import ProjectDownload from '@/views/Project/Download'
import ProjectImport from '@/views/Project/Import'
import ProjectCode from '@/views/Project/Code'
import PublicView from '@/views/Project/PublicView'
import Public from '@/views/Project/Public'

import Dashboard from '@/views/Dashboard'
import Upgrade from '@/views/Upgrade'
import Subscription from '@/views/Subscription'

import { auth } from '@/database'

Vue.use(Router)
Vue.use(Meta)

let router = new Router({
  mode: isElectron() ? 'hash' : 'history',
  routes: [
    {
      path: '*',
      redirect: '/sign-in'
    },
    {
      path: '/',
      name: 'Home',
      component: isElectron() ? Dashboard : Homepage,
      meta: {
        requiresAuth: isElectron(),
        hideSidebar: !isElectron()
      }
    },
    {
      path: '/app',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new',
      name: 'Create',
      component: Create,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/s/:secret',
      component: PublicView,
      props: true,
      meta: {
        hideSidebar: true
      },
      children: [
        {
          path: '',
          name: 'Public',
          component: Public,
          props: true
        },
        {
          path: 'use',
          name: 'PublicProjectCode',
          component: ProjectCode,
          props: true
        },
        {
          path: 'download',
          name: 'PublicProjectDownload',
          component: ProjectDownload,
          props: true
        }
      ]
    },
    {
      path: '/project/:id',
      component: ProjectView,
      props: true,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'Project',
          component: Project,
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'share',
          name: 'ProjectSharing',
          component: ProjectSharing,
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'settings',
          name: 'ProjectSettings',
          component: ProjectSettings,
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'export',
          name: 'ProjectExport',
          component: ProjectExport,
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'import',
          name: 'ProjectImport',
          component: ProjectImport,
          props: true,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/sign-in',
      name: 'SignIn',
      component: SignIn,
      meta: {
        hideSidebar: true
      }
    },
    {
      path: '/sign-out',
      name: 'SignOut',
      component: SignOut,
      meta: {
        hideSidebar: true
      }
    },
    {
      path: '/sign-up',
      name: 'SignUp',
      component: SignUp,
      meta: {
        hideSidebar: true
      }
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        hideSidebar: true
      }
    },
    {
      path: '/verify-email',
      name: 'VerifyEmail',
      component: VerifyEmail,
      meta: {
        hideSidebar: true
      }
    },
    {
      path: '/upgrade',
      name: 'Upgrade',
      component: Upgrade
    },
    {
      path: '/subscription',
      name: 'Subscription',
      component: Subscription
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        hideSidebar: true
      }
    },
    {
      path: '/how-to-create-custom-icon-font',
      name: 'CreateCustomIconFont',
      component: CreateCustomIconFont,
      meta: {
        hideSidebar: true
      }
    },
    {
      path: '/svg-sprite-vs-icon-font',
      name: 'SvgSpriteAndIconFont',
      component: SvgSpriteAndIconFont,
      meta: {
        hideSidebar: true
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  let currentUser = auth.currentUser
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) next('/sign-in')
  else if ((to.fullPath === '/sign-in' || to.fullPath === '/sign-up') && currentUser) next('/')
  else next()
})

router.afterEach((to) => {
  window.scrollTo(0, 0)
  // eslint-disable-next-line
  gtag && gtag('config', 'UA-1391454-61', {
    'page_title': to.name,
    'page_path': to.path
  })
})

export default router
