<template>
  <div class="Wrapper">
    <h5>Already have a font?</h5>
    <router-link :to="{ name: 'ProjectImport', params: { id: projectId } }">Import icons</router-link>
    from existing font
  </div>
</template>

<script>
export default {
  props: {
    projectId: {}
  }
}
</script>

<style lang="scss" scoped>
.Wrapper {
  position: absolute;
  bottom: 42px;
  font-size: $font-size-xs;
  text-align: center;
  width: 100%;
  a {
    color: $gray-700;
    text-decoration: underline;
  }
}
</style>
