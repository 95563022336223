import uuid from 'uuid'

import firebase from 'firebase/app'
import { db } from '@/database'
import toSlug from '@/utils/slug'

require('firebase/storage')

export function createIcon (file, projectId) {
  return new Promise((resolve, reject) => {
    const name = uuid.v4() + '.svg'
    const path = 'projects/' + projectId + '/icons/' + name

    Promise.resolve()
      // Upload icon to storage
      .then(() => {
        return uploadIcon(file, path)
      })
      // Get icon URL
      .then(result => {
        return result.metadata.ref.getDownloadURL()
      })
      // Save icon to project icons collection
      .then(url => {
        const icon = createIconObject(name, url, path, file, projectId)
        return saveProjectIcon(icon, projectId)
      })
      // Done!
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function updateIcon (data, iconId, projectId) {
  return new Promise((resolve, reject) => {
    db.collection('projects')
      .doc(projectId)
      .collection('icons')
      .doc(iconId)
      .update(data)
      .then(() => { resolve() })
      .catch((error) => { reject(error) })
  })
}

export function deleteIcon (iconId, projectId) {
  return new Promise((resolve, reject) => {
    db.collection('projects')
      .doc(projectId)
      .collection('icons')
      .doc(iconId)
      .delete()
      .then(() => { resolve() })
      .catch((error) => { reject(error) })
  })
}

function createIconObject (name, url, path, file, projectId) {
  const originalName = file.name
  return {
    url: url,
    path: path,
    project: projectId,
    fileName: name,
    createdAt: new Date(),
    className: toSlug(originalName.split('.').slice(0, -1).join('.')),
    originalName: originalName
  }
}

function uploadIcon (file, path) {
  // Create file metadata including the content type
  const metadata = {
    contentType: 'image/svg+xml',
    cacheControl: 'public,max-age=3600'
  }
  return firebase.storage()
    .ref(path)
    .put(file, metadata)
}

function saveProjectIcon (icon, projectId) {
  return db.collection('projects')
    .doc(projectId)
    .collection('icons')
    .add(icon)
}
