import uuid from 'uuid'
import axios from 'axios'

import { db, auth } from '@/database'
import toSlug from '@/utils/slug'

import { DEFAULT_ICON_PREFIX } from '@/constants'

export function createProject (data) {
  return new Promise((resolve, reject) => {
    const project = {
      ...data,

      createdAt: new Date(),

      owner: auth.currentUser.uid,
      users: [auth.currentUser.uid],
      secret: uuid.v4(),

      sharingEnabled: false,
      settings: {
        fontName: toSlug(data.name),
        iconPrefix: DEFAULT_ICON_PREFIX
      },
      downloadUrl: null
    }

    db.collection('projects').add(project).then((ref) => {
      resolve(ref)
    }).catch((error) => {
      reject(error)
    })
  })
}

export function updateProject (data, projectId) {
  return new Promise((resolve, reject) => {
    db.collection('projects')
      .doc(projectId)
      .update(data)
      .then(() => { resolve() })
      .catch((error) => { reject(error) })
  })
}

export function deleteProject (id) {
  return new Promise((resolve, reject) => {
    db.collection('projects').doc(id).delete().then(() => {
      resolve()
    }).catch((error) => {
      reject(error)
    })
  })
}

export function getFontUrl (id) {
  return `https://us-central1-webfontapp.cloudfunctions.net/generateFont?id=${id}`
}

export function generateFont (id) {
  const url = getFontUrl(id)
  return axios.get(url)
}

export function createInvitation (data) {
  return new Promise((resolve, reject) => {
    const { id, email } = data

    if (!id || !email) {
      return reject(new Error('Please provide invitation email'))
    }

    const doc = {
      createdAt: new Date(),
      createdBy: auth.currentUser.uid,
      project: id,
      email: email
    }

    db.collection('invitations').add(doc).then((ref) => {
      resolve(ref)
    }).catch((error) => {
      reject(error)
    })
  })
}

export function deleteInvitation (id) {
  return new Promise((resolve, reject) => {
    if (!id) {
      return reject(new Error('Please provide invitation id'))
    }

    try {
      // Remove invitation record
      db.collection('invitations').doc(id).delete().then(() => {
        resolve()
      })
    } catch (error) {
      reject(error)
    }
  })
}
