<template>
  <div>
    <link rel="stylesheet" href="https://use.typekit.net/gdc1gcx.css">

    <SiteHeader :user="user" class="" />

    <section class="bg-highlight hero position-relative">
      <div class="row flex-row-reverse no-gutters align-items-center">
        <div class="col-12 col-md-5 mr-auto">
          <div class="section-hero">
            <h1 class="mb-4">Create and organize your custom icon library</h1>
            <p class="lead mb-4" style="max-width: 520px;">
              Streamline the process of maintaining custom icon fonts and SVG sprites. Create a shared library of icons, invite your team and export your web icons in minutes.
            </p>
            <a target="_blank" href="https://github.com/virae/webfontapp-releases/releases/download/v0.9.6/Webfont-0.9.6.dmg" class="btn btn-primary btn-lg">Download for Free</a>
            <p class="small text-muted mt-4">
              Webfont app requires Mac OS 10.12 or newer
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4 hero-image-wrapper">
          <img class="hero-image" src="@/assets/images/hero-image.jpg" alt="Webfont manager app for Mac" />
        </div>
        <div class="d-none d-lg-flex" style="position: absolute; bottom: 1rem; right: 2rem;">
          <div class="mr-2">
            <div class="available-on-setapp-404"></div>
          </div>
          <a href="https://www.producthunt.com/posts/webfont?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-webfont" target="_blank">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=143510&theme=dark" alt="Webfont - Easily create and maintain custom SVG icon fonts | Product Hunt Embed" style="width: 183px; height: auto;" />
          </a>
        </div>
      </div>
    </section>

    <section id="learn-more">
      <div class="container section-block">

        <div class="row justify-content-center">
          <div class="col col-md-4 text-center">
            <h5 class="mt-4 mb-2">How it works</h5>
            <h2>
              Create a custom font in three simple steps
            </h2>
          </div>

          <div class="row mt-4">
            <div class="col-9 mr-auto ml-auto">
              <div class="row">
                <div class="col-12 col-md-4 p-4 pb-0 mt-2 mb-2">
                  <h4>Import your icons</h4>
                  <p>Create a project and add your vector icons. You can even import your existing font!</p>
                </div>
                <div class="col-12 col-md-4 p-4 pb-0 mt-2 mb-2">
                  <h4>Customize the font</h4>
                  <p>Customize each icon and give your font a name.</p>
                </div>
                <div class="col-12 col-md-4 p-4 pb-0 mt-2 mb-2">
                  <h4>Export and use</h4>
                  <p>Export your font, copy & paste generated CSS and use it on your website.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center small mb-4">
            <router-link :to="{ name: 'CreateCustomIconFont' }" title="Creating Your Own Icon Font in Three Simple Steps">
              <b>Read our Step by Step Guide &raquo;</b>
            </router-link>
          </div>

        </div>
      </div>
    </section>

    <section id="features">
      <div class="section-highlight">
        <div class="container">
          <div class="section-block">
            <div class="row align-items-center pb-md-5">
              <div class="col-12 col-md-6 m-md-auto order-md-5 mb-4">
                <img class="img-fluid" src="@/assets/images/features-store.svg" alt="Store all icons in one place" />
              </div>

              <div class="col-12 col-md-5">
                <h2>All icons in one place</h2>
                <p class="lead">Store and maintain your custom font library in one place. Create as many fonts and project as you need.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="container">
          <div class="section-block">
            <div class="row align-items-center pb-md-5">
              <div class="col-12 col-md-6 mb-4">
                <img class="img-fluid" src="@/assets/images/features-invite.svg" alt="Invite your team" />
              </div>

              <div class="col-12 col-md-5 m-md-auto">
                <h2>Invite your team</h2>
                <p class="lead">
                  Invite your team and keep everyone productive.
                  Invite <strong>designers</strong> to maintain the library and release new versions.
                  Invite <strong>developers</strong> to customize the code and build the font easily.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-highlight">
        <div class="container">
          <div class="section-block">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 m-md-auto order-md-5 mb-4">
                <img class="img-fluid" src="@/assets/images/features-export.svg" alt="Generate and export your font">
              </div>

              <div class="col-12 col-md-5">
                <h2>Export your font with ease</h2>
                <p class="lead text-h3">
                  Drag & drop your icons and click export! Your font is ready to be used.
                  Use the generated CSS on any website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="container">
          <div class="section-block">
            <div class="row align-items-center pb-md-5">
              <div class="col-12 col-md-6 mb-4">
                <img class="img-fluid" src="@/assets/images/features-share.svg" alt="Share your font" />
              </div>

              <div class="col-12 col-md-5 m-md-auto">
                <h2>Share</h2>
                <p class="lead">Privately share your font to make all font files and stylesheets available to anyone with the link.</p>
                <p class="lead">We support all common webfont formats (eot, woff, woff2, ttf, svg) and various stylesheet templates like CSS/SCSS/Less.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-highlight">
        <div class="container">
          <div class="section-block">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 m-md-auto order-md-5 mb-4 text-center">
                <img class="img-fluid" src="@/assets/images/features-maintain.svg" alt="Easily maintain your custom icons font">
              </div>

              <div class="col-12 col-md-5">
                <h2>Keep your font up-to-date</h2>
                <h5 class="mb-4 text-muted">Add new icons, export, repeat.</h5>
                <p class="lead">
                  Maintaining a custom font used to be a pain.
                  We made it dead simple – drag & drop more icons and export new version.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container section-block">

        <div class="col mx-auto mb-4 text-center">
          <h2>
            Pricing
          </h2>
          <p class="lead">
            Simple and straightforward pricing
          </p>
        </div>

        <div class="col col-12 col-md-12 col-lg-8 mt-4 pt-4 mx-auto">
          <Pricing />
        </div>

      </div>
    </section>

    <section>
      <div class="container section-block pt-0 d-none d-lg-block">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center p-8">
            <h5 class="mt-4 mb-2 text-dark">Trusted by many</h5>
            <p class="text-muted w-75 mx-auto">Join designers and developers who love building custom fonts with Webfont.</p>
          </div>
          <div class="col-12">
            <div class="row justify-content-center align-items-center text-center testimonial-logos">
              <div class="col-3 col-md-2 p-4">
                <img class="img-fluid" width="56" alt="UPS logo" src="@/assets/images/logos/ups.svg">
              </div>
              <div class="col-3 col-md-2 p-4">
                <img class="img-fluid w-100" width="156" alt="Custom Ink logo" src="@/assets/images/logos/customink.svg">
              </div>
              <div class="col-3 col-md-2 p-4">
                  <img class="img-fluid w-100" width="196" alt="Represent logo" src="@/assets/images/logos/represent.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-highlight">
      <div class="container section-block">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center p-8">
            <h2 class="display-4 mb-4">Ready to get started?</h2>
            <p class="lead mb-4">Download the app and start organizing your custom font.</p>
            <a target="_blank" href="https://github.com/virae/webfontapp-releases/releases/download/v0.9.6/Webfont-0.9.6.dmg" class="btn btn-primary btn-lg">Download for Free</a>
            <p class="small text-muted mt-4">
              Webfont app requires Mac OS 10.12 or newer
            </p>
          </div>
        </div>
      </div>
    </section>

    <footer class="footer footer-small">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 text-lg-left">
            <div class="mb-3">
              <img alt="Custom icon font manager app" src="@/assets/images/logo.png" height="20" />
            </div>
            <div class="text-small">
              <small>
                Create and manage your own SVG icon fonts.
              </small>
            </div>
          </div>
        </div>
        <div class="row align-items-center mt-4">
          <div class="col-12 col-md-6 text-lg-left">
            <small>© {{ new Date().getFullYear() }} webfontapp.com, All Rights Reserved.</small>
          </div>
          <div class="col-12 col-md-6 text-lg-right">
            <small>
              <a class="unstyled-link" href="mailto:hello@webfontapp.com">Get support</a>
              &nbsp;|&nbsp;
              <router-link to="/privacy" class="unstyled-link">Terms &amp; Privacy Policy</router-link>
              &nbsp;|&nbsp;
              <a title="Intuitive, training-focused power meter cycling analytics" target="_blank" class="unstyled-link" href="https://www.formbeat.com">FormBeat Cycling Analytics</a>
            </small>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { auth } from '@/database'
import SiteHeader from '@/components/SiteHeader'
import Pricing from '@/components/Pricing'

export default {
  name: 'homepage',
  components: { SiteHeader, Pricing },
  metaInfo () {
    return {
      title: 'Webfont – Create and manage custom icon fonts & SVG sprites'
    }
  },
  data () {
    return {
      user: auth.currentUser
    }
  },
  mounted () {
    window._setappBtn = [{
      vendorID: 288,
      appID: 404,
      selector: '.available-on-setapp-404'
    }];

    let setAppScript = document.createElement('script')
    setAppScript.setAttribute('src', 'https://developer.setapp.com/setapp-button/setapp.js')
    document.head.appendChild(setAppScript)
  }
}
</script>

<style lang="scss" scoped>  /* "scoped" attribute limit the CSS to this component only */
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  h1, h2 {
    font-family: poynter-oldstyle-display, serif;
    font-weight: 700;
    font-style: normal;
    color: #333;
  }
  h1 {
    font-size: 42px;
    @media screen and (min-width: 960px) {
      font-size: 56px;
      max-width: 520px;
    }
  }
  h2 {
    font-size: 36px;
  }
  h4 {
    font-weight: 600;
  }
  .unstyled-link {
    color: inherit;
  }
  .footer {
    padding: 32px;
    border-top: 1px solid $gray-200;
  }
  .hero {
    padding: 0;
  }
  .section-hero {
    padding: 24px;
  }
  .section-block {
    padding: 32px;
  }
  @media screen and (min-width: 960px) {
    .hero {
      padding: 32px 0;
    }
    .section-hero {
      padding: 0;
      max-width: 640px;
    }
    .section-block {
      padding-top: 64px;
      padding-bottom: 32px;
    }
  }
  .hero-image-wrapper {
    overflow: hidden;
    @media screen and (min-width: 768px) {
      height: 560px;
    }
  }
  .hero-image {
    width: 140%;
    padding-bottom: 32px;
    @media screen and (min-width: 768px) {
      position: absolute;
      top: 0;
      right: 20px;
      width: 900px;
      padding-bottom: 0;
    }
  }
  .testimonial-logos img {
    opacity: 0.6;
  }
</style>
