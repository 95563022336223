<template>
  <div v-if="user && userProfile" class="Account">

    <div class="Account__user">
      <Avatar :email="user.email" size="lg" class="mb-4" />

      <h2>{{ user.email }}</h2>
      <h5>Member since {{ registeredAt }}</h5>

      <div v-if="isPremiumAccount">
        <div class="badge mt-4">
          Premium account
        </div>
      </div>
    </div>

    <div v-if="!isPremiumAccount" class="Account__upgrade">
      <div>
        <h5>Do more with premium account</h5>
        <h4>Webfont Premium</h4>
        <ul class="list-unstyled">
          <li><img src="@/assets/images/check.svg" height="18" alt=""> Unlimited projects &amp; fonts</li>
          <li><img src="@/assets/images/check.svg" height="18" alt=""> Unlimited users and collaborators</li>
          <li><img src="@/assets/images/check.svg" height="18" alt=""> Unlimited icons</li>
          <li><img src="@/assets/images/check.svg" height="18" alt=""> Email support</li>
        </ul>
        <a href="#" class="btn btn-primary my-4" @click="openCheckout">Upgrade for <b>$9.99 / year</b></a>
      </div>
    </div>

    <div class="Account__sign-out">
      <router-link v-if="hasSubscription" class="btn btn-sm btn-outline-primary mr-2" to='/subscription'>Manage Subscription</router-link>
      <router-link class="btn btn-sm btn-outline-primary" to='/sign-out'>Sign Out</router-link>
    </div>
  </div>
</template>

<script>
import { auth, db } from '@/database'
import Avatar from '@/components/Avatar'
import { upgradeAccount } from '@/actions/AccountActions'
import { mapActions } from 'vuex'
import isElectron from '@/utils/isElectron'
import openUrl from '@/utils/openUrl'

export default {
  name: 'Account',
  components: { Avatar },
  data () {
    return {
      user: auth.currentUser,
      userProfile: null
    }
  },
  mounted () {
    this.setAppLoading(true)
    this.$binding('userProfile', db.collection('users').doc(auth.currentUser.uid)).then(() => {
      this.setAppLoading(false)
    })
    // eslint-disable-next-line
    Paddle.Setup({ vendor: 37930 })
  },
  computed: {
    isPremiumAccount () {
      return this.userProfile && this.userProfile.plan === 'premium'
    },
    hasSubscription () {
      return this.userProfile && this.userProfile.plan === 'premium' && this.userProfile.subscription && this.userProfile.subscription.id
    },
    registeredAt () {
      if (!this.userProfile || !this.userProfile.createdAt) {
        return ''
      }
      const createdAt = new Date(this.userProfile.createdAt.seconds * 1000)
      return createdAt.toDateString()
    }
  },
  methods: {
    openCheckout () {
      // Electron does not support popups (e.g PayPal popup, Bank verification, ...)
      if (isElectron()) {
        const params = [
          'disable_logout=true',
          `guest_email=${encodeURIComponent(auth.currentUser.email)}`,
          `passthrough=${auth.currentUser.uid}`,
          'quantity_variable=0'
        ]
        openUrl(`https://pay.paddle.com/checkout/586348?${params.join('&')}`)
      } else {
        const handlePaddleResponse = (result) => {
          if (result.checkout && result.checkout.completed === true) {
            upgradeAccount(auth.currentUser.uid).catch((error) => {
              alert('Error: ' + error.message)
            })
          }
        }
        // eslint-disable-next-line
        Paddle.Checkout.open({
          product: 586348,
          email: auth.currentUser.email,
          passthrough: auth.currentUser.uid,
          allowQuantity: false,
          disableLogout: true,
          closeCallback: handlePaddleResponse,
          successCallback: handlePaddleResponse
        })
      }
    },
    ...mapActions([
      'setAppLoading'
    ])
  }
}
</script>

<style scoped lang="scss">
.Account {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10vh 0 0;
}
.Account__user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Account__sign-out {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.Account__upgrade {
  flex-grow: 1;
  width: 100%;
  background-color: $gray-100;
  margin-top: 3rem;
  border-top: 1px solid $gray-300;
  padding: 2rem 2rem 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    margin: 1rem 0 0;
    padding: 0;
  }
  h4 {
    font-weight: bold;
  }
  ul li {
    padding: 0.2rem 0;
  }
  ul li + li {
    border-top: 1px solid $gray-200;
  }
}
.badge {
  padding: $padding-sm/2 $padding-sm;
  margin: 0 0 $margin-md 0;
  border-radius: 4px;
  color: $green;
  border: 1px solid $green;
}
</style>
