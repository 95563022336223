<template>
  <div class="bg-highlight">
    <SiteHeader />
    <div class="terms">
      <div class="container">
        <h2>Terms of Service and Privacy Policy</h2>
        <hr>
        <h3>1. Terms</h3>
        <p>By accessing the website at <a href="https://webfontapp.com">https://webfontapp.com</a>, you agree to be bound by these terms of service, all applicable laws and regulations, and you are responsible for compliance with any local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
        <h3>2. Use License</h3>
        <p>Permission is granted to create and download any number of custom fonts using Webfont's web application for personal, commercial, or public display purposes. However, you may not use materials available on Webfont's website (such as templates, graphics, or other proprietary assets) for any purpose without explicit permission. Under this license, you may:</p>
        <ol type="a">
          <li>modify or copy the custom fonts you create using the web application;</li>
          <li>use the custom fonts for any commercial purpose or public display;</li>
          <li>transfer the custom fonts to another person or "mirror" the custom fonts on any other server;</li>
        </ol>
        <h3>3. Disclaimer</h3>
        <ol type="a">
          <li>The materials on Webfont's website are provided on an 'as is' basis. Webfont makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
          <li>Further, Webfont does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
        </ol>
        <h3>4. Limitations</h3>
        <p>In no event shall Webfont or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Webfont's website, even if Webfont or a Webfont authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
        <h3>5. Accuracy of materials</h3>
        <p>The materials appearing on Webfont website could include technical, typographical, or photographic errors. Webfont does not warrant that any of the materials on its website are accurate, complete or current. Webfont may make changes to the materials contained on its website at any time without notice. However Webfont does not make any commitment to update the materials.</p>
        <h3>6. Links</h3>
        <p>Webfont has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Webfont of the site. Use of any such linked website is at the user's own risk.</p>
        <h3>7. Modifications</h3>
        <p>Webfont may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
        <h3>8. Governing Law</h3>
        <p>These terms and conditions are governed by and construed in accordance with the laws of Czech Republic and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
        <h2 class="mb-4 mt-4">Privacy Policy</h2>
        <p>Your privacy is important to us. It is Webfont's policy to respect your privacy regarding any information we may collect from you across our website, https://webfontapp.com.</p>
        <p>We don’t ask for your personal information unless we truly need it. When we do, we’ll only collect what we need by fair and lawful means and, where appropriate, with your knowledge or consent. We’ll also let you know why we’re collecting it and how it will be used.</p>
        <p>We don’t share your personal information with third-parties, except where required by law. We will only retain personal information for as long as necessary to provide you with a service.</p>
        <p>We don’t store your personal information on our servers unless it’s required for providing a service to you. What we store, we’ll protect within commercially acceptable means to protect your personal information from loss or theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
      </div>
    </div>
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader'

export default {
  name: 'privacy',
  components: { SiteHeader },
  metaInfo () {
    return {
      title: 'Terms of Service and Privacy Policy'
    }
  }
}
</script>

<style scoped>
  .terms {
    max-width: 720px;
    padding: 32px 24px 96px 24px;
    margin: 0 auto;
    background: #dfedee;
  }
  h3 {
    font-size: 24px;
    margin: 24px 0 12px 0;
  }
</style>
