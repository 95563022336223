import Vue from 'vue'
import App from './App'
import router from './router'
import store from '@/store'
import filters from '@/filters'
import { auth } from '@/database'
import isElectron from '@/utils/isElectron'
import isProduction from '@/utils/isProduction'

Object.entries(filters).forEach(([name, method]) => {
  Vue.filter(name, method)
})

Vue.config.productionTip = false

if (isElectron() && isProduction()) {
  const Sentry = require('@sentry/electron')
  Sentry.init({ dsn: 'https://55e54dfe285b448c91c184092ab7874f@sentry.io/1868066' })
}

var app

auth.onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      components: { App },
      render: h => h(App)
    }).$mount('#app')
  }
})
