import isElectron from '@/utils/isElectron'

export default function (url) {
  if (isElectron()) {
    const { shell } = window.require('electron')
    shell.openExternal(url)
  } else {
    window.open(url)
  }
}
