<template>
  <div class="card-deck my-3 text-center">
    <div class="card mb-4 shadow-lg">
      <div class="card-header">
        <h4 class="my-0">Free</h4>
      </div>
      <div class="card-body">
        <h5>Small projects</h5>
        <div class="card-title">$0</div>
        <ul class="card-list list-unstyled mt-3 mb-4">
          <li>Unlimited projects &amp; fonts</li>
          <li>Unlimited users</li>
          <li>16 icons per project</li>
          <!-- <li>No CDN support</li> -->
          <li>Free forever</li>
        </ul>
      </div>
    </div>
    <div class="card mb-4 shadow-lg">
      <div class="card-header">
        <h4 class="my-0">Pro</h4>
      </div>
      <div class="card-body">
        <h5>In-app purchase</h5>
        <div class="card-title">$9.99 / year</div>
        <ul class="list-unstyled mt-3 mb-4">
          <ul class="card-list list-unstyled mt-3 mb-4">
            <li>Unlimited projects &amp; fonts</li>
            <li>Unlimited users</li>
            <li>Unlimited icons</li>
            <!-- <li>CDN font hosting</li> -->
            <li>Email support</li>
          </ul>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-title {
  font-size: 2rem;
  font-weight: bold;
}
.card-list {
  li {
    padding: 6px;
  }
  li + li {
    border-top: 1px solid $gray-300;
  }
}
</style>

<script>
export default {}
</script>
