<template>
  <div></div>
</template>

<script>
import { db, auth } from '@/database'
import { mapActions } from 'vuex'

const VERIFY_USER_EMAIL = false

export default {
  name: 'dashboard',
  methods: {
    ...mapActions([
      'setAppLoading'
    ])
  },
  mounted () {
    this.setAppLoading(true)
  },
  firestore () {
    return {
      projects: {
        ref: db.collection('projects').where('users', 'array-contains', auth.currentUser.uid),
        resolve: (data) => {
          this.setAppLoading(false)
          if (VERIFY_USER_EMAIL && !auth.currentUser.emailVerified) {
            this.$router.push('/verify-email')
          } else if (data.length === 0) {
            this.$router.push('/new')
          } else {
            this.$router.push({ name: 'Project', params: { id: data[0]['.key'] } })
          }
        },
        reject: (error) => {
          console.error('Error: ', error.message)
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
