<template>
  <div v-if="isPremiumAccount && hasSubscription" class="Subscription">

    <div class="Subscription__details">
      <h3>Your Subscription</h3>
      <hr />
      <h5>🙌🏼Thank you for using Webfont Premium</h5>
      <hr />

      <table>
        <tr>
          <td style="width: 200px"><b>Your Plan:</b></td>
          <td>Yearly Subscription</td>
        </tr>
        <tr>
          <td><b>Price:</b></td>
          <td>{{ subscription.currency }} {{ subscription.unitPrice }}</td>
        </tr>
        <tr>
          <td><b>Status:</b></td>
          <td>{{ subscription.status | capitalize }}</td>
        </tr>
        <tr v-if="subscription.paymentMethod" key="paymentMethod">
          <td><b>Payment Method:</b></td>
          <td>{{ subscription.paymentMethod | capitalize }}</td>
        </tr>
        <tr v-if="hasActiveSubscription" key="activeSubscription">
          <td><b>Next Payment:</b></td>
          <td>{{ subscription.nextBillingDate | date }}</td>
        </tr>
        <tr v-else key="activeSubscription">
          <td><b>Membership Expires:</b></td>
          <td>{{ subscription.cancelationEffectiveDate | date }}</td>
        </tr>
      </table>

      <div class="my-4">
        <a v-if="hasActiveSubscription" @click="updateSubsription()" class="btn btn-sm btn-outline-secondary mr-2">Update Payment Method</a>
        <a v-if="hasActiveSubscription" @click="cancelSubsription()" class="btn btn-sm btn-outline-secondary">Cancel Subscription</a>
      </div>
    </div>

    <div class="Subscription__illustration">
      <div>
        <img src="@/assets/images/custom-icons.svg" alt="" style="width: 100%">
      </div>
    </div>

    <div class="Subscription__navigation">
      <router-link class="Account__sign-out btn btn-sm btn-outline-primary" to='/upgrade'>Close</router-link>
    </div>
  </div>
</template>

<script>
import { auth, db } from '@/database'
import { mapActions } from 'vuex'
import openUrl from '@/utils/openUrl'

export default {
  data () {
    return {
      user: auth.currentUser,
      userProfile: null
    }
  },
  mounted () {
    this.setAppLoading(true)
    this.$binding('userProfile', db.collection('users').doc(auth.currentUser.uid)).then(() => {
      this.setAppLoading(false)
    })
  },
  computed: {
    subscription () {
      return this.userProfile && this.userProfile.subscription
    },
    isPremiumAccount () {
      return this.userProfile && this.userProfile.plan === 'premium'
    },
    hasSubscription () {
      return this.subscription && this.subscription.id
    },
    hasActiveSubscription () {
      return this.subscription && this.subscription.status === 'active'
    }
  },
  methods: {
    ...mapActions([
      'setAppLoading'
    ]),
    cancelSubsription() {
      if (confirm('Do you really want to cancel your subscription?')) {
        openUrl(this.subscription.cancelUrl)
      }
    },
    updateSubsription() {
      openUrl(this.subscription.updateUrl)
    }
  }
}
</script>

<style scoped lang="scss">
.Subscription {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10vh 0 0;
  overflow: hidden;
}
.Subscription__details {
  flex-grow: 1;
}
.Subscription__navigation {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.Subscription__illustration {
  padding: 6rem;
  width: 100%;
}
</style>
