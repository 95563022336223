<template>
  <div></div>
</template>

<script>
import { auth } from '@/database'

export default {
  name: 'signOut',
  created: function () {
    auth.signOut().then(() => {
      this.$router.replace('/sign-in')
    }, (error) => {
      alert('Error: ', error.message)
    })
  }
}
</script>
