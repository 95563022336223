<template>
  <div class="Project__body Project__body--flex">
    <div class="text-center" style="max-width: 480px; margin-top: -3rem">
      <h4 class="mb-4 mt-0">Download</h4>
      <p class="text-muted mb-4">
        Click below download the font. It will contain your font in all common web font formats as well as custom CSS templates ready to be used on your website.
      </p>
      <a :href="project.downloadUrl" target="_blank" class="btn btn-primary">
        Download
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectDownload',
  props: {
    project: {
      default: {}
    }
  },
  metaInfo () {
    return {
      title: `${this.project ? this.project.name : ''} | Download`
    }
  }
}
</script>

<style lang="scss" scoped>
.Project__body--flex {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
