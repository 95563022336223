<template>
  <div class="auth">
    <div class="auth__form card">
      <h2 class="mb-3 text-center">Hey there!</h2>
      <div class="auth__switch mb-3">
        <router-link to="/sign-in" class="auth__switchItem auth__switchItem--active">Login</router-link>
        <div class="auth__switchSeparator">or</div>
        <router-link to="/sign-up" class="auth__switchItem">Sign up</router-link>
      </div>
      <div class="form-group">
        <label class="form-label">Email</label>
        <input type="email" v-model="email" placeholder="Email" class="form-control">
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col">
            <label class="form-label">Password</label>
          </div>
          <div class="col text-right">
            <small><router-link to="/reset-password" tabindex="-1">Forgot password?</router-link></small>
          </div>
        </div>
        <input @keyup.enter="signIn" type="password" v-model="password" placeholder="Password" class="form-control">
      </div>
      <div class="form-group">
        <button v-on:click="signIn" :disabled="isLoading" class="btn btn-primary btn-lg btn-block">Sign in</button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/database'

export default {
  name: 'signIn',
  metaInfo () {
    return {
      title: 'Sign in'
    }
  },
  data: function () {
    return {
      email: '',
      password: '',
      isLoading: false
    }
  },
  created () {
    if (auth.currentUser) {
      this.$router.replace('/app')
    }
  },
  methods: {
    signIn () {
      this.isLoading = true
      auth.signInWithEmailAndPassword(this.email, this.password).then(
        () => {
          this.$router.push('/app')
        },
        (err) => {
          alert('Error: ' + err.message)
        }
      ).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
