<template>
  <div class="Icon">
    <img :src="icon.url" alt="" />
    <LoadingInput :loading="isLoading" class="Icon__input">
      <input @blur="updateClassName" v-model="className" type="text" class="form-control" />
    </LoadingInput>
  </div>
</template>

<script>
import toSlug from '@/utils/slug'
import LoadingInput from '@/components/LoadingInput'
import { updateIcon } from '@/actions/IconActions'

export default {
  name: 'IconClassname',
  props: ['icon'],
  components: { LoadingInput },
  data () {
    return {
      isLoading: false,
      className: this.icon.className
    }
  },
  methods: {
    updateClassName (e) {
      const className = toSlug(e.target.value)
      e.target.value = className
      if (className !== this.icon.className) {
        this.isLoading = true
        const data = {
          className: e.target.value
        }
        updateIcon(data, this.icon['.key'], this.icon.project).finally(() => {
          this.isLoading = false
        })
      }
    }
  },
  watch: {
    icon (val) {
      if (val.className) {
        this.className = val.className
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Icon {
    width: 46%;
    flex: 0 1 46%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    overflow: hidden;
    margin: $margin-sm 0;

    &:nth-child(odd) {
      margin-right: 2%;
    }
    &:nth-child(even) {
      margin-left: 2%;
    }
  }

  .Icon__label {
    max-width: 80%;
    font-size: $font-size-xs;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Icon__input {
    width: 100%;
  }
  .Icon img {
    opacity: 0.8;
    width: 36px;
    height: auto;
    margin: 0 $margin-sm 0 0;
  }
</style>
