<template>
  <div class="Input__container">
    <slot></slot>
    <div class="Input__loading">
      <LoadingIcon :small="true" v-if="loading" />
    </div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon'
export default {
  name: 'LoadingInput',
  components: { LoadingIcon },
  props: {
    loading: { type: Boolean }
  }
}
</script>

<style lang="scss" scoped>
.Input__container {
  position: relative;
}
.Input__loading {
  position: absolute;
  right: $margin-sm;
  top: 50%;
  transform: translateY(-60%);
}
</style>
