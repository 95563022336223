export default function currency(value) {
  if (typeof value === 'string') {
    value = Number(value)
  }

  if (typeof Intl === 'undefined') {
    return typeof value === 'number' ? '$' + value.toFixed(2) : ''
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  return typeof value === 'number' ? formatter.format(value) : ''
}
