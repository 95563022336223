<template>
  <header class="Header" :class="modifiers">
    <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4">
      <router-link class="my-0 mr-md-auto navbar-brand" to="/">
        <img src="@/assets/images/logo.png" height="24" alt="Webfont app - simple webfont and icon library manager for mac os" />
      </router-link>
      <nav class="my-2 my-md-0 mr-md-3">
        <router-link class="mx-3" title="Home" to="/">Home</router-link>
        <router-link class="mx-3" title="Features" to="/#features">Features</router-link>
        <router-link class="mx-3" :to="{ name: 'SvgSpriteAndIconFont' }" title="Icon fonts vs. SVG - Which one should I use">Icon fonts vs SVGs</router-link>
        <router-link class="mx-3" title="Sig in" to="/sign-in" v-if="!user">Sign in</router-link>
        <router-link class="mx-3" title="Dashboard" to="/app" v-if="user">Dashboard</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'siteHeader',
  props: ['user', 'isLight'],
  computed: {
    modifiers () {
      return {
        isLight: this.isLight === true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Header {
  background-color: $blue-light;

  a {
    font-weight: 500;
    font-size: $font-size-sm;
    color: inherit;
  }

  &.isLight {
    background-color: white;
  }
}
</style>
