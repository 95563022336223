<template>
  <div class="Project__body">
    <div class="Settings__section">
      <h5>Code</h5>
      <pre style="width: 960px; white-space: pre-wrap; overflow: auto">{{ cssTemplate }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectCode',
  props: ['project', 'icons', 'id'],
  metaInfo () {
    return {
      title: `${this.project ? this.project.name : ''} | Code`
    }
  },
  computed: {
    cssTemplate () {
      if (!this.project || !this.project.templates) {
        return ''
      }
      return this.project.templates.css
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.Settings__section {
  & + & {
    margin-top: 2rem;
  }
}
</style>
