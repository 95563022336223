<template>
  <div class="auth">
    <div class="auth__form card">
      <h2 class="mb-3 text-center">Hey there!</h2>
      <div class="auth__switch mb-3">
        <router-link to="/sign-in" class="auth__switchItem">Login</router-link>
        <div class="auth__switchSeparator">or</div>
        <router-link to="/sign-up" class="auth__switchItem auth__switchItem--active">Sign up</router-link>
      </div>

      <div class="form-group">
        <label class="form-label">Your name</label>
        <input type="text" v-model="name" placeholder="Your name" class="form-control">
      </div>
      <div class="form-group">
        <label class="form-label">Email &amp; Password</label>
        <input type="email" v-model="email" placeholder="Email" class="form-control mb-2">
        <input @keyup.enter="signUp" type="password" v-model="password" placeholder="Password" class="form-control">
      </div>
      <div class="form-group">
        <button v-on:click="signUp" :disabled="isLoading" class="btn btn-primary btn-lg btn-block">Sign Up</button>
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from '@/database'
const VERIFY_USER_EMAIL = false

export default {
  name: 'signUp',
  metaInfo () {
    return {
      title: 'Create your account'
    }
  },
  data: function () {
    return {
      email: '',
      password: '',
      name: '',
      isLoading: false
    }
  },
  created () {
    if (auth.currentUser) {
      this.$router.replace('/app')
    }
  },
  methods: {
    signUp () {
      this.isLoading = true
      auth.createUserWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          db.collection('users').doc(user.user.uid).set({
            uid: user.user.uid,
            createdAt: new Date(),
            plan: 'free',
            name: this.name
          }).then(() => {
            auth.currentUser.sendEmailVerification().then(() => {
              // Email sent
              if (VERIFY_USER_EMAIL) {
                this.$router.push('/verify-email')
              } else {
                this.$router.push('/app')
              }
            }).catch((error) => {
              alert('Error: ' + error.message)
            })
            auth.currentUser.updateProfile({
              displayName: this.name
            })
          }).catch((error) => {
            alert('Error: ' + error.message)
          })
        }).catch((error) => {
          alert('Error: ' + error.message)
        }).finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style>
</style>
