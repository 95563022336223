<template>
  <div class="Sidebar" :class="{'Sidebar--is-desktop': isElectron}">
    <div class="Sidebar__header">
      <router-link class="d-block p-1 mb-4" to="/app" v-if="!isElectron">
        <img src="@/assets/images/logo.png" height="20" alt="Webfont app - simple webfont and icon library manager for mac os">
      </router-link>
      <div class="Sidebar__label">Projects</div>
    </div>
    <div class="Sidebar__body" :class="{'Sidebar__body--is-empty': !projects.length}" v-if="!isLoading">
      <div v-if="!projects.length">
        No projects
      </div>
      <div v-if="projects.length" v-for="project in projects" v-bind:key="project.name">
        <router-link class="Sidebar__link" :to="{ name: 'Project', params: { id: project['.key'] }}">{{ project.name }}</router-link>
      </div>
      <hr class="mb-1">
      <router-link class="Sidebar__link" to='/new'>+ Add new project</router-link>
    </div>

    <div class="Sidebar__footer">

      <router-link class="Sidebar__link mb-1" to='/upgrade'>
        <Avatar :email="userEmail" size="sm" class="mr-1" />
        Account
      </router-link>

    </div>
  </div>
</template>

<script>
import { db, auth } from '@/database'
import Avatar from '@/components/Avatar'
import isElectron from '@/utils/isElectron'

export default {
  name: 'sidebar',
  components: { Avatar },
  data () {
    return {
      isLoading: true,
      projects: [],
      userId: auth.currentUser && auth.currentUser.uid,
      userEmail: auth.currentUser && auth.currentUser.email
    }
  },
  computed: {
    isElectron () {
      return isElectron()
    }
  },
  mounted () {
    if (auth.currentUser) {
      this.$binding(
        'projects',
        db.collection('projects')
          .where('users', 'array-contains', auth.currentUser.uid)
      ).then(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .Sidebar {
    top: 0;
    left: 0;
    padding: $sidebar-padding;
    padding-top: 1.33rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    width: $sidebar-width;
  }
  .Sidebar.Sidebar--is-desktop {
    padding-top: $padding-lg;
  }
  .Sidebar__header {
    flex-grow: 0;
  }
  .Sidebar__body {
    flex-grow: 1;
    align-self: stretch;
    width: 100%;

    &--is-empty {
      padding: $sidebar-padding;
    }
  }
  .Sidebar__footer {
    flex-grow: 0;
  }

  .Sidebar__link {
    display: block;
    width: 100%;
    font-size: $font-size-sm;
    padding: $sidebar-padding/2 $sidebar-padding;
    border-radius: $sidebar-padding/2;
    color: black;
    cursor: inherit;

    &:hover {
      cursor: default;
      text-decoration: none;
      color: black;
    }

    &.router-link-active {
      background: $brown-dark;
      color: white;
      box-shadow: 2px 2px 16px rgba(0,0,0,0.01);
    }
  }
  .Sidebar__label {
    margin-bottom: $sidebar-padding/2;
    color: $gray-600;
    text-transform: uppercase;
    font-size: $font-size-xs;
    font-weight: bold;
    padding: $sidebar-padding/2 $sidebar-padding;
  }
</style>
