<template>
  <div class="Project__body Project__body--flex">
    <div class="Project__form">
      <img class="Project__image mb-4" src="@/assets/images/empty-state.svg" />
      <h2 class="h3 mb-4 text-center">Create a new project</h2>
      <label class="form-label mb-2">Project name</label>
      <div class="form-group">
        <input ref="projectName" @keyup.enter="submit" type="text" v-model="name" class="form-control" placeholder="Enter project name" />
      </div>
      <div class="form-group mb-0">
        <button @click="submit" class="btn btn-primary btn-block">Create project</button>
      </div>
    </div>
  </div>
</template>

<script>
import { createProject } from '@/actions/ProjectActions'

export default {
  name: 'create',
  metaInfo () {
    return {
      title: 'Create a New Project'
    }
  },
  data () {
    return {
      name: null
    }
  },
  mounted () {
    this.$refs.projectName.focus()
  },
  methods: {
    submit () {
      createProject({ name: this.name }).then((ref) => {
        this.$router.push({ name: 'Project', params: { id: ref.id } })
      }).catch((error) => {
        console.error('error', error)
      })
    }
  }
}
</script>

<style scoped>
.Project__body--flex {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Project__form {
  width: 280px;
  max-width: 280px;
}
.Project__image {
  display: block;
  width: 75%;
  margin: 0 auto;
}
</style>
