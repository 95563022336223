<template>
  <div>
    <SiteHeader :isLight="true" />
    <div class="Static-page">

      <img src="@/assets/images/custom-icons.svg" alt="Create your custom icon font" class="img-fluid hero-image" />

      <h1>Create Your Own Icon Font in Three Simple Steps</h1>

      <p style="font-size: 1.66rem;">Over the past few years, icon fonts have grown in popularity and have been widely used on many websites as an alternative to more traditional approach of bitmap images (e.g. png, jpg).</p>

      <p>There are many advantages of using icon fonts over bitmaps, but the most important difference is in the file size (vector icons are much smaller than bitmap images), scalability (icons can be scaled to any dimensions without loosing image quality) and they can be easily customized (for example. you can easily change the color of a vector icon) or even animated! Adding vector icons to your website is easier than ever and one of the best ways to use them is through a custom webfont. Let’s take a closer look what icon fonts are and how to create your own webfont in no time.</p>

      <h2>What Is an Icon Font?</h2>

      <p>But first things first – what is an icon font and what are some of the advantages of using it on your website? Icon fonts are very much just regular fonts (as any other webfonts used on the web – e.g. Roboto, or Open Sans), but instead of letters and numbers, they contain symbols and icons. As soon as you include your font on your website, each icon can be then very easily used in HTML or referenced in CSS.</p>

      <p>Here are some advantages to <b>organizing your vector icons into an icon font</b>:</p>

      <ul class="mb-4">
        <li><b>Fast loading time</b>: all your icons are available and cached after just one http request (loading the font file)</li>
        <li>You can easily <b>change the size and color</b> of your icons with CSS selectors</li>
        <li>Organizing your icons into one custom font <b>keeps your codebase nice and tidy</b>. There are no SVG files all over the codebase, just a font file containing all your interface icons.</li>
      </ul>

      <br>

      <p class="label mb-0 mt-4">Using Webfont app for Mac</p>
      <h2>How to create your own icon font</h2>

      <h3>1) Import your existing icons</h3>
      <p>Export your existing icons into SVG files and open Webfont app. Create a new project in the app and drag & drop exported SVG files.</p>

      <h3>2) Customize the font and icons</h3>
      <p>Filenames of your original SVG files are going to be used as your custom icon CSS classnames when you export your font. If you wish to make and changes, customize each icon in the Settings section.</p>

      <h3>3) Export and use it on your website</h3>
      <p>Navigate to the Export section and generate your icon font. Downloaded file will include your font available in the most common webfont formats as well as simple usage instructions for your website or blog.</p>

      <p>Enjoy!</p>
    </div>
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader'

export default {
  name: 'privacy',
  components: { SiteHeader },
  metaInfo () {
    return {
      title: 'Create Your Own Icon Font in Three Simple Steps | Webfont',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Learn how to add vector icons to your website with custom icon font.'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .Static-page {
    max-width: 720px;
    padding: 32px 24px 96px 24px;
    margin: 0 auto;
  }
  h1 {
    font-family: poynter-oldstyle-display, serif;
    font-weight: 700;
    font-style: normal;
    color: #333;
  }
  h1 {
    font-size: 42px;
    margin-bottom: 2rem;
    @media screen and (min-width: 960px) {
      font-size: 56px;
    }
  }
  h2 {
    font-size: 36px;
    margin-bottom: 1.66rem;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 1rem 0;
  }
  p, ul {
    font-size: 1.15rem;
    line-height: 1.55em;
    margin: 1rem 0 1.66rem 0;
  }
  .hero-image {
    margin-top: -5rem;
  }
  .label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    color: $gray-600;
  }
</style>
