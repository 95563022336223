<template>
  <div v-if="project" class="Project">
    <div class="Project__header">
      <div class="Project__title" :class="{ 'Project__title--focused': projectNameEditing }">
        <input
          ref="projectNameRef"
          type="text"
          class="Project__name"
          v-model="projectName"
          @blur="updateProjectName"
          @focus="projectNameInputEvent"
          @focusout="projectNameInputEvent"
          @keydown.esc="revertProjectName"
          @keydown.enter="submitProjectName"
          :size="projectName.length + 3"
        />
      </div>
      <div class="Project__actions">
        <input @input="search($event.target.value)" type="search" placeholder="Search icons" class="Nav__search form-control form-control-sm" :value="searchQuery" v-if="$route.name === 'Project'" />

        <router-link class="Project__share" :to="{ name: 'ProjectSharing', params: { id: project['.key'] } }">
          <img src="@/assets/images/share.svg" width="12" />
          Share
        </router-link>
      </div>
      <div class="Project__nav">
        <ProjectNav
          :search-query="searchQuery"
          :project="project"
          @onSearch="search"
        />
      </div>
    </div>

    <router-view
      :project="project"
      :search-query="searchQuery"
      :icons="icons"
      :owner="owner"
      :id="id"
    />
  </div>
</template>

<script>
import { db } from '@/database'
import ProjectNav from '@/views/Project/Nav'
import { mapActions } from 'vuex'
import { deleteIcon } from '@/actions/IconActions'
import { updateProject } from '@/actions/ProjectActions'

export default {
  name: 'project',
  components: { ProjectNav },
  props: ['id'],
  metaInfo () {
    return {
      title: this.project ? this.project.name : '',
      titleTemplate: '%s'
    }
  },
  data () {
    return {
      project: null,
      projectName: this.project ? this.project.name : '',
      projectNameEditing: false,
      icons: [],
      searchQuery: undefined,
      owner: {}
    }
  },
  mounted () {
    this.getProjectData()
  },
  methods: {
    getProjectData () {
      this.setAppLoading(true)
      Promise.all([
        this.$binding('project', db.collection('projects').doc(this.id)),
        this.$binding('icons', db.collection('projects').doc(this.id).collection('icons').orderBy('className'))
      ]).then(() => {
        this.projectName = this.project.name
        Promise.all([
          this.$binding('owner', db.collection('users').doc(this.project.owner))
        ]).then(() => {
          this.setAppLoading(false)
        })
      }).catch(() => {
        this.$router.replace('/app')
      })
    },
    deleteProjectIcon (icon) {
      deleteIcon(icon['.key'], this.id).then(() => {
        // toast?
      }).catch((error) => {
        console.log('error', error)
      })
    },

    // Project name
    projectNameChanged () {
      return this.projectName !== this.project.name
    },
    revertProjectName () {
      this.projectName = this.project.name
      this.$refs.projectNameRef.blur()
    },
    submitProjectName () {
      this.$refs.projectNameRef.blur()
    },
    updateProjectName () {
      this.projectName = this.projectName || this.project.name

      if (!this.projectNameChanged()) {
        return
      }

      const data = {
        name: this.projectName
      }
      updateProject(data, this.id)
    },
    projectNameInputEvent (e) {
      if ((e.type === 'focus' || e.type === 'keyup')) {
        this.projectNameEditing = true
      } else {
        this.projectNameEditing = false
      }
    },
    search(value) {
      this.searchQuery = value
    },

    ...mapActions([
      'setAppLoading'
    ])
  },
  watch: {
    id () { this.getProjectData() }
  }
}
</script>

<style lang="scss">
</style>
