<template>
  <div class="Upload">
    <div v-if="!uploadInProgress">
      <h5>Add {{ iconsCount ? "more" : "your" }} icons</h5>
      Drag &amp; drop or <a href="javascript: void(0)" @click="selectFile">select files</a> to upload
    </div>
    <div v-else>
      <LoadingIcon></LoadingIcon>
    </div>
    <input
      id="files"
      type="file"
      name="file"
      ref="uploadInput"
      accept="image/svg+xml"
      :multiple="true"
      @change="detectFiles($event)" />
  </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon'
export default {
  props: {
    iconsCount: {},
    project: {},
    uploadInProgress: {},
    accept: {
      type: Array,
      default: () => ([])
    }
  },
  components: { LoadingIcon },
  methods: {
    selectFile () {
      this.$refs.uploadInput.click()
    },
    detectFiles (e) {
      e.preventDefault()
      e.stopPropagation()

      let files = Array.from(e.target.files || e.dataTransfer.files)

      if (this.accept && this.accept.length) {
        files = files.filter((file) => {
          return this.accept.includes(file.type)
        })
      }

      if (files.length) {
        this.$emit('onFiles', files)
      }
    }
  }
}
</script>

<style lang="scss">
.Upload {
  font-size: $font-size-xs;
  color: $gray-700;
  padding: $padding-lg;
  text-align: center;
  width: 100%;
}
.Upload a {
  color: $gray-700;
  text-decoration: underline;
}
input[type="file"] {
  position: absolute;
  clip: rect(0,0,0,0)
}
</style>
