<template>
  <div class="Project__body Project__body--flex">
    <div class="text-center" style="max-width: 480px; margin-top: -3rem">
      <h4 class="mb-4 mt-0">Import your font</h4>
      <p class="mb-4">
        Click below and upload <strong>.svg</strong> version of your font. We will extract all your existing icons and classNames.
      </p>
      <div>
        <button class="btn btn-primary" href="#" @click="selectFile" :disabled="isLoading">
          <LoadingIcon v-if="isLoading" :white="true" :small="true" style="margin-right: 4px; vertical-align: baseline" />
          <span v-if="isLoading">We're importing your font</span>
          <span v-else>Select Font</span>
        </button>
      </div>
    </div>
    <input
      id="files"
      type="file"
      name="file"
      ref="uploadInput"
      accept="image/svg+xml"
      :multiple="false"
      @change="importFont($event)" />
  </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon'
import { createIcon } from '@/actions/IconActions'
import isElectron from '@/utils/isElectron'

export default {
  name: 'ProjectImport',
  props: {
    project: {
      default: null
    },
    icons: {},
    id: {}
  },
  components: {
    LoadingIcon
  },
  metaInfo () {
    return {
      title: `${this.project ? this.project.name : ''} | Import existing font`
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    selectFile () {
      this.$refs.uploadInput.click()
    },
    handleFileUpload (files) {
      this.isLoading = true
      const uploadQueue = files.map((file) => {
        const fileObject = new File([file[0]], file[1], {
          type: 'image/svg+xml'
        })
        return createIcon(fileObject, this.id)
      })
      Promise.all(uploadQueue).then(() => {
        this.$router.push({ name: 'Project', params: { id: this.id } })
      }).finally(() => {
        this.isLoading = false
      })
    },
    importFont (e) {
      e.preventDefault()
      e.stopPropagation()

      if (!isElectron()) {
        return
      }

      let files = Array.from(e.target.files || e.dataTransfer.files)

      if (this.accept && this.accept.length) {
        files = files.filter((file) => {
          return this.accept.includes(file.type)
        })
      }

      if (files.length) {
        var reader = new FileReader()

        reader.addEventListener('load', async () => {
          const { ipcRenderer } = window.require('electron')
          const result = await ipcRenderer.invoke('importFont', reader.result)
          this.handleFileUpload(result)
        }, false)

        reader.readAsText(files[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Project__body--flex {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
input[type="file"] {
  position: absolute;
  clip: rect(0,0,0,0)
}
</style>
