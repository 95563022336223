<template>
  <div class="Project__body" v-if="project">
    <div class="Members">

      <div v-if="isProjectOwner">
        <h5 class="mb-0">
          Invite people to project
        </h5>

        <div class="row align-items-center">
          <div class="col">
            <h4 class="my-0">Members</h4>
          </div>
          <div class="col col-auto">
            <div class="form-inline">
              <div class="form-group mr-2">
                <input @keyup.enter="submit" type="email" v-model="email" class="form-control" placeholder="E-mail" />
              </div>
              <div class="form-group">
                <button :disabled="!validateEmail() || isLoading" @click="submit" class="btn btn-primary btn-block">
                  Invite
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="Member mt-4">
          <div class="Member__user">
            <Avatar :email="userEmail" class="mr-2" />
            <div>
              {{ userDisplayName }}
              (<strong>You</strong>)
            </div>
          </div>
          <div class="Member__role">
            Owner
          </div>
        </div>

        <div v-if="members" v-for="member in members" v-bind:key="member.email" class="Member">
          <div class="Member__user">
            <Avatar :email="member.email" class="mr-2" />
            {{ member.email}}
          </div>
          <div class="Member__role">
            Invited
            <a href="#" @click.prevent="removeInvitation(member['.key'], member.email)" class="Member__remove">Remove</a>
          </div>
        </div>
      </div>

      <h4 class="mt-4 pt-3 mb-3">
        Share project via Link
      </h4>
      <input onClick="this.select()" class="share-link" type="url" readonly :value="getShareUrl()">
      <p class="text-muted mt-3">
        The private share link allows people to view the project without giving access to full collaboration features.
      </p>

    </div>
  </div>
</template>

<script>
import { db, auth } from '@/database'
import { createInvitation, deleteInvitation } from '@/actions/ProjectActions'
import openUrl from '@/utils/openUrl'
import Avatar from '@/components/Avatar'

export default {
  name: 'ProjectSharing',
  props: ['project', 'id'],
  components: { Avatar },
  data () {
    return {
      isLoading: false,
      email: null,
      members: null,
      userId: auth.currentUser && auth.currentUser.uid,
      userEmail: auth.currentUser && auth.currentUser.email,
      userDisplayName: auth.currentUser && auth.currentUser.displayName
    }
  },
  metaInfo () {
    return {
      title: `${this.project ? this.project.name : ''} | Manage users`
    }
  },
  mounted () {
    this.getMembers()
  },
  computed: {
    isProjectOwner () {
      return auth.currentUser.uid === this.project.owner
    }
  },
  methods: {
    getShareUrl () {
      return `https://webfontapp.com/s/${this.project.secret}`
    },
    share () {
      openUrl(this.getShareUrl())
    },
    validateEmail () {
      return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
    },
    getMembers () {
      Promise.all([
        this.$binding('members', db.collection('invitations')
          .where('createdBy', '==', auth.currentUser.uid)
          .where('project', '==', this.id))
      ])
    },
    removeInvitation (id, email) {
      if (confirm(`Do you really want to remove access for "${email}"?`)) {
        deleteInvitation(id)
      }
    },
    submit () {
      if (!this.members.find((member) => { return member.email === this.email })) {
        this.isLoading = true
        createInvitation({ id: this.project['.key'], email: this.email }).then((ref) => {
          this.email = null
          this.isLoading = false
          console.log(ref)
        }).catch((error) => {
          this.isLoading = false
          console.error('error', error)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h5 {
    font-size: 12px;
  }
  .Members {
    font-size: 14px;
  }
  .Member {
    padding: 12px 0;
    border: 1px solid #eee;
    border-width: 1px 0;
    border-collapse: collapse;
    margin-top: -1px;

    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .Member__user {
    display: flex;
    align-items: center;
  }
  .Member__role {
    font-weight: 600;
    text-align: right;
  }
  .Member__remove {
    display: inline-block;
    margin-left: 8px;
    color: #dc3545;
    font-weight: bold;
    text-align: center;
  }

  .share-link {
    display: block;
    width: 100%;
    padding: $padding-sm 1rem;
    background: $gray-100;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 14px;
    border: 1px solid $gray-300;
    border-radius: 4px;
  }
</style>
