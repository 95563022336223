<template>
  <div class="Icon">
    <img :src="icon.url" alt="" />
    <div class="Icon__label">
      {{ icon.className }}
    </div>
    <a class="Icon__delete" v-if="onDelete" @click="onDelete(icon)">&times;</a>
  </div>
</template>

<script>

export default {
  name: 'icon',
  props: ['icon', 'onDelete']
}
</script>

<style lang="scss" scoped>
  .Icon {
    position: relative;
    width: calc(100%/5);
    height: calc((60vw)/5);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    overflow: hidden;
    color: $gray-600;
    padding: $padding-sm;
  }

  .Icon__delete {
    position: absolute;
    text-align: center;
    top: 4px;
    right: 10px;
    font-size: 18px;
    font-weight: bold;
    display: none;

    .Icon:hover & {
      cursor: pointer;
      display: block;
    }
  }

  .Icon__label {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    padding: $padding-sm;
    opacity: 0;
    text-align: center;
    background: rgba(255, 255, 255, 0.66);
    font-size: $font-size-xs;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .Icon:hover & {
      opacity: 1;
      animation: labelAppear 0.2s ease-out;
    }
  }
  .Icon img {
    opacity: 0.85;
    width: auto;
    height: 48px;

    animation: iconAppear 0.33s ease-out;
  }

  @keyframes iconAppear {
    0% {
      opacity: 0;
      transform: scale(0.94) translateZ(0);
    }
    100% {
      opacity: 0.85;
      transform: scale(1.0) translateZ(0);
    }
  }

  @keyframes labelAppear {
    0% {
      opacity: 0;
      transform: translateY(4px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
</style>
