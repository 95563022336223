<template>
  <div class="App">
    <div class="App__sidebar" v-if="! hideSidebar">
      <Sidebar />
    </div>
    <div class="App__loading" v-if="isLoading">
      <LoadingIcon></LoadingIcon>
    </div>
    <div class="App__content" :class="{'App__content--is-hidden': isLoading}">
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import LoadingIcon from '@/components/LoadingIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Webfont – Create and manage custom SVG icon fonts',
      // description: 'Streamline the process of creating and managing custom SVG icon fonts. Create a shared library of icons, invite your team and export your font in minutes.',
      // all titles will be injected into this template
      titleTemplate: '%s | Webfont app for Mac',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Generate custom SVG icon fonts. Create a shared library and invite your team.'
        }
      ]
    }
  },
  components: { Sidebar, LoadingIcon },
  computed: {
    hideSidebar () {
      return this.$route.matched.some(record => record.meta.hideSidebar)
    },
    ...mapGetters([
      'isLoading'
    ])
  }
}
</script>

<style lang="scss">
  @import 'stylesheets/app';

  html {
    height: 100%;
  }
  body {
    user-select: none;
    font-family: $body-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $body-color;
    display: flex;
    margin: 0;
    padding: 0;
  }
  h5 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    color: $gray-600;
  }
  .form-label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    color: $gray-800;
  }
  .form-control:focus {
    outline: none;
    box-shadow: none !important;
  }
  .App {
    flex: 1;
    display: flex;
    height: 100%;
    min-height: 100vh;
  }
  .App::before {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 36px;
    content: '';
    -webkit-app-region: drag;
    pointer-events: none;
  }
  .App__sidebar {
    padding: 36px;
    min-width: 220px;
    background: $brown-light;
    align-self: stretch;
    flex: 0 1 220px;
  }
  .App__content {
    flex: 1;
    &--is-hidden {
      display: none;
    }
  }
  .App__loading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .navbar-brand {
    padding: 18px;
  }
  .unstyled-link {
    color: inherit;
  }
  .nav-item + .nav-item {
    margin-left: 16px;
  }
  .section-highlight {
    background-color: $gray-100;
  }

  .auth {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100%;
    background-image: url(~@/assets/images/auth-background.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-color: #D6E2D9;
    background-size: auto 82%;
  }

  .auth__form {
    align-self: center;
    padding: 36px;
    position: absolute;
    left: 12vw;
    top: 50%;
    transform: translate(0, -220px);
    min-width: 360px;
    max-width: 360px;
    border: none;
    box-shadow: 0px 4px 40px rgba(0,0,0,0.1);
    h2 {
      font-weight: $font-weight-bold;
    }
  }

  .auth__switch {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
  }

  .auth__switchSeparator {
    margin: 0 1rem;
    font-size: $font-size-sm;
    color: $gray-700;
    align-self: center;
  }

  .auth__switchItem {
    font-size: $font-size-sm;
    position: relative;
    font-weight: bold;
    color: lighten($primary, 10%);
    padding: 0.4rem 0;

    &:hover {
      text-decoration: none;
    }

    &--active {
      color: $primary;
      border-bottom: 2px solid $primary;
    }
  }

  .bg-highlight {
    background-color: $blue-light;
  }
  .bg-gray {
    background-color: $gray-200;
  }

  .button {
    padding: 8px 16px;
    background: blue;
    color: white;
  }

  .Project {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .Project__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    /* box-shadow: inset 0 0 1px rgba(0,0,0,0.8); */
    border-bottom: 1px solid rgba(0,0,0,0.1);
    position: relative;
    /* height: 128px; */
    max-height: 128px;
  }

  .Project__title {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    position: relative;
    transition: background .2s ease-in-out;

    padding: 8px 2.4rem;
    font-size: 32px;
    border-bottom: 1px solid rgba(0,0,0,0.05);

    &--focused {
      background: lighten($brown-light, 12%);
    }
  }

  .Project__name {
    background-color: transparent;
    font-size: 32px;
    box-shadow: none;
    outline: none;
    border: none;
    max-width: 520px;
    overflow: hidden;
  }

  .Project__actions {
    position: absolute;
    right: 1.4rem;
    top: 1.2rem;

    display: flex;
  }
  .Project__share {
    padding: 0.25rem 1.33rem 0.25rem 1.2rem;
    height: calc(1.85rem + 2px);
    line-height: 1.5;
    display: flex;
    align-items: center;
    background: $primary;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.9;
    &:hover {
      color: white;
      opacity: 1;
      text-decoration: none;
    }

    img {
      margin-right: 8px;
    }
  }
  .Project__nav {
    display: flex;
    flex-direction: column;
    margin-bottom: -1px;
    /* justify-content: space-between; */
  }
  .Project__icons {
    background: #eee;
    flex: 0 1 auto;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
  }
  .Project__body {
    width: 100%;
    height: 100%;
    padding: 48px;
    overflow: hidden;
    overflow-y: auto;
  }

  .Nav {
    display: flex;
    justify-content: flex-start;
    margin: 0 1.4rem 0 32px;
    align-items: center;
  }

  .Nav__link {
    margin: 0 8px;
    padding: 16px 8px;
    font-size: 14px;
    line-height: 1em;
    color: $gray-700;
    position: relative;

    &:hover {
      color: black;
      text-decoration: none;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display:block;
      content: '';
      border-bottom: solid 2px $black;
      transform: scaleX(0);
      transition: transform 125ms ease-in-out;
    }

    &.router-link-exact-active,
    &--active {
      color: black;

      &::after {
        transform: scaleX(1);
      }
    }
  }

  .Nav__search {
    margin-right: 8px;
    max-width: 156px;
    padding-left: 16px;
    border-radius: 16px;
  }
</style>
