import Vue from 'vue'
import VueFirestore from 'vue-firestore'
import firebase from 'firebase/app'

Vue.use(VueFirestore)

require('firebase/firestore')
require('firebase/storage')
require('firebase/auth')

const ENABLE_PERSISTENCE = false

const config = {
  apiKey: 'AIzaSyAW-beHRo795ZQ3H85bDThykySDCRRzDpE',
  authDomain: 'webfontapp.firebaseapp.com',
  databaseURL: 'https://webfontapp.firebaseio.com',
  projectId: 'webfontapp',
  storageBucket: 'webfontapp.appspot.com',
  messagingSenderId: '476112297528'
}

export const firebaseApp = firebase.initializeApp(config)
export const db = firebaseApp.firestore()
export const storage = firebaseApp.storage()
export const auth = firebaseApp.auth()

if (ENABLE_PERSISTENCE) {
  db.enablePersistence()
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    })
}
