<template>
  <div class="auth">
    <div class="auth__form card">
      <h2 class="mb-3 text-center">
        <span v-if="!success">Password Reset</span>
        <span v-if="success">Please check your inbox</span>
      </h2>
      <div v-if="!success">
        <p>
          To reset your password, enter the email address you use to sign in to Webfont.
        </p>
        <div class="form-group">
          <label class="form-label">Email</label>
          <input type="text" v-model="email" placeholder="you@example.com" class="form-control" />
        </div>
        <div class="form-group">
          <button v-on:click="resetPassword" :disabled="isLoading" class="btn btn-primary btn-lg btn-block">Reset Password</button>
        </div>
        <p class="text-center mb-0 mt-4">
          Go back to <router-link to="/sign-in"><strong>Sign in</strong></router-link>
        </p>
      </div>
      <div v-else>
        <p>
          Email sent to <strong>{{email}}</strong>! Check your inbox for instructions on how to reset your password.
        </p>
        <p class="mt-4">
          <router-link to="/sign-in" class="btn btn-block btn-primary btn-lg">Go back to Sign in</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/database'

export default {
  name: 'resetPassword',
  metaInfo () {
    return {
      title: 'Reset your password'
    }
  },
  data: function () {
    return {
      email: '',
      success: null,
      isLoading: false
    }
  },
  created () {
    if (auth.currentUser) {
      this.$router.replace('/app')
    }
  },
  methods: {
    resetPassword () {
      this.isLoading = true
      auth.sendPasswordResetEmail(this.email).then(
        () => {
          this.success = true
        },
        (err) => {
          alert(err.message)
        }
      ).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
