const state = {
  isLoading: false
}

const getters = {
  isLoading (state) {
    return state.isLoading
  }
}

const mutations = {
  setLoadingState (state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  setAppLoading ({commit}, payload) {
    commit('setLoadingState', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
