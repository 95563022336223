<template>
  <Dropzone @onFiles="handleFileUpload" class="Project__body Project__body--icons" if="icons.length" :accept="['image/svg+xml']">
    <!-- Icons -->
    <Icon :icon="icon" :onDelete="deleteProjectIcon" v-for="icon in activeIcons" :key="icon.url" />
    <!-- Upload -->
    <Upload :iconsCount="icons.length" :uploadInProgress="uploadInProgress" :accept="['image/svg+xml']" :project="project" @onFiles="handleFileUpload" />
    <!-- Font import -->
    <FontImport :projectId="id" v-if="allowFontImport" />
  </Dropzone>
</template>

<script>
import Icon from '@/components/Icon'
import Upload from '@/components/Upload'
import FontImport from '@/components/FontImport'
import Dropzone from '@/components/Dropzone'
import { createIcon, deleteIcon } from '@/actions/IconActions'
import isElectron from '@/utils/isElectron'

export default {
  name: 'project',
  components: { Icon, Dropzone, Upload, FontImport },
  props: ['project', 'icons', 'id', 'searchQuery'],
  metaInfo () {
    return {
      title: `${this.project ? this.project.name : ''} | Icons`
    }
  },
  data () {
    return {
      uploadInProgress: false
    }
  },
  computed: {
    allowFontImport () {
      return isElectron() && !this.icons.length
    },
    activeIcons() {
      if (this.searchQuery) {
        return this.icons.filter(({ className }) => className.indexOf(this.searchQuery) > -1)
      } else {
        return this.icons
      }
    }
  },
  methods: {
    deleteProjectIcon (icon) {
      if (confirm(`Do you really want to delete icon "${icon.className}"?`)) {
        deleteIcon(icon['.key'], this.id).then(() => {
          // TODO: toast?
        }).catch((error) => {
          console.log('error', error)
        })
      }
    },
    handleFileUpload (files) {
      this.uploadInProgress = true
      const uploadQueue = files.map((file) => {
        return createIcon(file, this.project['.key'])
      })
      Promise.all(uploadQueue).finally(() => {
        this.uploadInProgress = false
      })
    }
  }
}
</script>

<style lang="scss">
  .Project__body--icons {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 0;
  }
</style>
