<template>
  <div v-if="project" class="Project">
    <div class="Project__header">

      <router-link class="Project__logo" to="/">
        <img src="@/assets/images/logo.png" height="24" alt="Webfont app - simple webfont and icon library manager for mac os">
      </router-link>

      <div class="Project__title">
        {{ project.name }}
      </div>

      <!-- <div class="Project__search">
        <input @input="search($event.target.value)" type="search" placeholder="Search icons" class="form-control form-control-sm" :value="searchQuery" />
      </div> -->

      <div class="Project__nav">
        <div class="Nav" v-if="project && project['.key']">
          <router-link class="Nav__link" :to="{ name: 'Public', params: { id: project['.key'] } }">
            Icons
          </router-link>
          <router-link class="Nav__link" :to="{ name: 'PublicProjectCode', params: { id: project['.key'] } }">
            Use
          </router-link>
          <router-link class="Nav__link" :to="{ name: 'PublicProjectDownload', params: { id: project['.key'] } }" v-if="project.downloadUrl">
            Download
          </router-link>
        </div>
      </div>
    </div>

    <router-view
      :project="project"
      :icons="icons"
      :id="project['.key']"
    />
  </div>
</template>

<script>
import { db } from '@/database'
import { mapActions } from 'vuex'

export default {
  name: 'Public',
  props: ['secret'],
  data () {
    return {
      project: null,
      searchQuery: undefined,
      icons: []
    }
  },
  methods: {
    initialize () {
    },
    ...mapActions([
      'setAppLoading'
    ])
  },
  mounted () {
    this.setAppLoading(true)
    this.$binding(null, db.collection('projects').where('secret', '==', this.secret)).then((docs) => {
      this.project = docs[0]
      this.$binding('icons', db.collection('projects').doc(this.project['.key']).collection('icons'))
      this.setAppLoading(false)
    })
  }
}
</script>

<style lang="scss" scoped>
  .Project {}
  .Project__icons {
    display: flex;
    flex-wrap: wrap;
  }
  .Project__logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
  .Project__title {
    text-align: center;
  }
</style>
