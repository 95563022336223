<template>
  <div class="Project__body Project__body--flex">
    <div class="text-center" style="max-width: 480px; margin-top: -3rem">
      <h4 class="mb-4 mt-0">Export</h4>
      <div v-if="canExportProject">
        <p class="text-muted mb-4">
          Click below to export and download your font. It will contain your font in all common web font formats as well as custom CSS templates ready to be used on your website.
        </p>
        <button class="btn btn-primary" href="#" @click="download" :disabled="isLoading">
          <LoadingIcon v-if="isLoading" :white="true" :small="true" style="margin-right: 4px; vertical-align: baseline" />
          <span v-if="isLoading">Exporting your font</span>
          <span v-else>Export &amp; Download</span>
        </button>
      </div>
      <div v-else>
        <div class="lead mb-4">
          Oops. Your font has more than 16 icons allowed for free projects.
          <span class="lead mb-4" v-if="isProjectOwner">
            Please upgrade your account to export your font.
          </span>
          <span class="lead mb-4" v-else>
            Please ask the owner of this project to upgrade to Premium account.
          </span>
        </div>
        <router-link v-if="isProjectOwner" class="btn btn-primary" to='/upgrade'>
          Learn more
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/database'
import { generateFont, getFontUrl } from '@/actions/ProjectActions'
import isElectron from '@/utils/isElectron'
import LoadingIcon from '@/components/LoadingIcon'

export default {
  name: 'ProjectExport',
  props: {
    project: {
      default: null
    },
    icons: {},
    owner: {},
    id: {}
  },
  components: {
    LoadingIcon
  },
  metaInfo () {
    return {
      title: `${this.project ? this.project.name : ''} | Export`
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    isProjectOwner () {
      return auth.currentUser.uid === this.project.owner
    },
    canExportProject () {
      return (this.icons.length <= 16 || this.owner.plan === 'premium')
    }
  },
  methods: {
    async download () {
      this.isLoading = true
      if (isElectron()) {
        const url = getFontUrl(this.project['.key'])
        const { ipcRenderer } = window.require('electron')
        await ipcRenderer.invoke('downloadFont', url, this.project.settings.fontName)
        this.isLoading = false
      } else {
        generateFont(this.project['.key']).then((response) => {
          window.open(response.data)
        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          this.isLoading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Project__body--flex {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
