<template>
  <div>
    <SiteHeader :isLight="true" />
    <div class="Static-page">

      <img src="@/assets/images/custom-icons.svg" alt="Create your custom icon font" class="img-fluid hero-image" />

      <h1>Icon Fonts vs. SVG sprite: Which should I use?</h1>

      <p><b>Webfont app</b> allows you to automate the process of creating a custom icon font or SVG sprite for your website – but how do these two common formats compare?</p>

      <p>Icons as interface elements have come a long way in the past two decades - from inline images, through the custom image sprites to custom icon fonts and SVGs. Developers were always trying to find the optimal and innovative techniques to organize interface icons and achieve the best performance and user experience.</p>
      <p>These days, web developers have two primary options: icon fonts or SVGs. While icon fonts were a big step forward and are still very popular (they introduced a simple way to add vector, scalable graphics that could be re-colored), SVG, as a native vector format is becoming more and more popular. Which one should you use for your new project? Let&#39;s take a look at how these two formats compare in terms of performance, accessibility, and ease of customization.</p>

      <h2>Comparing SVGs vs Icon Fonts</h2>

      <h3>Size</h3>
      <p>Icon fonts are usually exported in several different formats and their size varies. Woff2  (The Web Open Font Format 2.0) - a common format supported by the most modern web browsers will have a smaller size than external SVG sprite containing all your icons. As a general rule, an SVG sprite served with GZIP Compression will have 1.5x – 2.5x the size of the icon font. An Icon font is a clear winner in file size, but even for larger icon libraries containing more than 100 icons, the potential saving is often less 10–20Kb of size. </p>
      <p><b>Winner:</b> Icon Fonts</p>

      <h3>User experience</h3>
      <p>Both icon fonts and external SVG sprites can be cached by the browser after the first request, therefore making them load without a delay for each subsequent request. From the user experience perspective, icon fonts can suffer from FOUT effect (Flash of unstyled text) while the font is being loaded. Even though there are several strategies to improve this behavior, it adds some complexity to the process of loading an icon font. </p>
      <p>As a native image format, SVGs tend to have higher rendering quality as browsers often optimize rendering of fonts, which are treated as text and subject to anti-aliasing.</p>
      <p><b>Winner:</b> No clear winner</p>

      <h3>Accessibility</h3>
      <p>Accessibility is the practice of making your website usable by as many people as possible.</p>
      <p>Icon fonts can easily cause some accessibility issues when used without a correct markup. <a href="https://www.w3.org/WAI/standards-guidelines/aria/">WAI-ARIA</a> specification and features can help to solve most common problems (e.g. aria-hidden attribute for icons with no semantical meaning or aria-label attribute to provide the label to any assistive technologies), icon fonts fall short when the user request browser to always use their preferred font (for example, a font designed for dyslexics). What gets displayed is either a Unicode symbol or a meaningless letter.</p>
      <p>One of the biggest advantages of SVG icons over icon fonts is their native support for  accessibility options. SVGs have built-in semantic elements – like &lt;title&gt; and &lt;desc&gt; that makes it accessible to screen reader and text browsers. Furthermore, SVGs are treated as an image instead of text. </p>
      <p><b>Winner:</b> SVG Icons</p>

      <h3>Customization</h3>
      <p>You can easily customize the size and color of both icon fonts and SVGs with some basic CSS techniques. Inlined SVGs also allow to customize and animate SVG elements (strokes, paths, ...) to easily create multicolored animated icons. </p>
      <p><b>Winner:</b> SVG Icons</p>

      <h3>Browser Support</h3>
      <p>Icon fonts are widely supported even in very old browsers (IE 6+). SVGs are supported in all modern browsers but for IE8 and below, you will probably have to use <a href="https://github.com/jonathantneal/svg4everybody">svg4everybody</a> polyfill.</p>
      <p><b>Winner:</b> tie / Icon fonts</p>

      <h3>Conclusion</h3>
      <p>
        We even tried to <a href="https://www.getvoila.ai/ask-ai" title="ask ai">ask AI</a> and get a definitive answer by letting AI to help us decide which format is better. AI recommends to use SVG sprites for modern, high-quality, accessible, and flexible icons, while icon fonts for simple legacy projects requiring broad browser compatibility.
      </p>
      <p>
        Every project has unique needs and considerations and icon fonts are here to stay, we invite you to give SVGs a try in your new project!
        Use our app to easily generate any format you prefer - an icon font or SVG icon sprite.
      </p>
    </div>
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader'

export default {
  name: 'privacy',
  components: { SiteHeader },
  metaInfo () {
    return {
      title: 'Icon Fonts versus SVG sprite – which is better? | Webfont',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'A guide comparing SVGs and Custom Icon Fonts'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .Static-page {
    max-width: 720px;
    padding: 32px 24px 96px 24px;
    margin: 0 auto;
    user-select: text;
  }
  h1 {
    font-family: poynter-oldstyle-display, serif;
    font-weight: 700;
    font-style: normal;
    color: #333;
  }
  h1 {
    font-size: 42px;
    margin-bottom: 2rem;
    @media screen and (min-width: 960px) {
      font-size: 56px;
    }
  }
  h2 {
    font-size: 36px;
    margin-bottom: 1.66rem;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 1rem 0;
  }
  p, ul {
    font-size: 1.15rem;
    line-height: 1.55em;
    margin: 1rem 0 1.66rem 0;
  }
  .hero-image {
    margin-top: -5rem;
  }
  .label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    color: $gray-600;
  }
</style>
